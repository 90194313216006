<template>
  <div class="company">
    <div class="company-body">
      <div class="company-body-header company-width">
        <div class="company-body-header-left">
          <el-select
            v-model="bankLeftId"
            @change="dataLeftFun(bankLeftId)"
            placeholder="请选择"
            style="width: 240px; margin-right: 20px"
          >
            <el-option
              v-for="item in bankLeftNameList"
              :key="item.bankcardId"
              :label="item.bankNameText"
              :value="item.bankcardId"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            style="position: relative"
            @click="avatarFun()"
          >
            <i class="el-icon-plus"></i> 上传银行对账单
          </el-button>
          <el-button type="primary" style="position: relative">
            <a
              class="download-link"
              href="https://www.jzcfo.com/download/银行对账单模版文件.xlsx"
              >下载模版</a
            >
          </el-button>
        </div>
        <div class="company-body-header-right">
          <el-select
            v-if="BankFlowList.length !== 0"
            v-model="bankName"
            @change="dataFun(bankName)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in bankNameList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="company-body-content company-width">
        <table class="accountTable" v-if="BankFlowList.length !== 0">
          <thead>
            <tr>
              <!-- <th>序号</th> -->
              <th>交易日</th>
              <th>交易时间</th>
              <th>交易流水号</th>
              <th>对方账户名称</th>
              <th>对方账号</th>
              <th>对方账号开户网点名称</th>
              <th>收款金额</th>
              <th>付款金额</th>
              <th>账户余额</th>
              <th>用途</th>
              <th>附言</th>
              <th>处理原因</th>
              <th v-if="rowNum > 0">操作</th>
            </tr>
          </thead>
          <tbody>
            <!-- e.shoukuanAmtSum = 0; e.payAmtSum = 0; -->
            <slot v-for="(item, index) in BankFlowList">
              <!-- {{ index }} -->
              <tr :key="index + '1--'" v-if="item.text">
                <td colspan="13" class="report-colspan">
                  <div class="colspan-left">
                    <span class="colspan-span1">{{ item.text }}月</span>
                    <span class="colspan-span2"
                      >收入 ¥{{ item.shoukuanAmtSum.toFixed(2) }}</span
                    >
                    <span class="colspan-span2"
                      >支出 ¥{{ item.payAmtSum.toFixed(2) }}</span
                    >
                  </div>
                  <div class="colspan-right" v-if="0">
                    <el-select
                      v-model="value"
                      placeholder="请选择"
                      v-if="index === 0"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </td>
              </tr>
              <tr :key="index + '2--'">
                <!-- <td>{{ index + 1 }}</td> -->
                <td>
                  <div class="report-span1">{{ item.transDate }}</div>
                </td>
                <td>
                  <div class="report-span1">{{ item.transTime }}</div>
                </td>
                <td>
                  <div class="report-span7">{{ item.transFlowNo }}</div>
                </td>
                <td>
                  <div class="report-span3">
                    {{ item.duifangAccountName }}
                  </div>
                </td>
                <td>
                  <div class="report-span4">{{ item.duifangAccountNo }}</div>
                </td>
                <td>
                  <div class="report-span4">
                    {{ item.duifangBank }}
                  </div>
                </td>
                <td>
                  <div class="report-span5">{{ item.shoukuanAmt }}</div>
                </td>
                <td>
                  <div class="report-span5">{{ item.payAmt }}</div>
                </td>
                <td>
                  <div class="report-span5">{{ item.accountBalance }}</div>
                </td>
                <td
                  :class="{ reportHover: reportFlag }"
                  @click="editClicl(item)"
                >
                  <span class="report-edit">
                    <svg-icon icon-class="qianbi" class="qianbi"></svg-icon>
                  </span>
                  <div class="report-span5">{{ item.yongtu }}</div>
                </td>
                <td>
                  <div class="report-span6">{{ item.fuyan }}</div>
                </td>
                <td>
                  <div class="report-span6">{{ item.reason }}</div>
                </td>
                <td v-if="rowNum > 0">
                  <!--  -->
                  <div
                    v-if="item.state !== 1"
                    class="report-span8"
                    @click="deleteBankFlow(item)"
                  >
                    删除
                  </div>
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
        <NoRecord v-else></NoRecord>
      </div>
    </div>
    <TheFooter></TheFooter>
    <div class="popup-layer" v-if="yongtuShow">
      <div class="popup-layer-bg" @click="yongtuShow = false"></div>
      <div class="password-main">
        <div class="popup-layer-close" @click="yongtuShow = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="addStaffPopup-header">用途修改</div>
        <div class="password-content">
          <div class="password-content-box">
            <div class="password-box-l">用途</div>
            <div class="password-box-r">
              <div class="paddword-text">
                <input type="text" v-model="item.yongtu" placeholder="请输入" />
              </div>
            </div>
          </div>
          <div class="earmarked-footer">
            <div class="earmarked-btn" @click="modifyBankFlowYongTu">保 存</div>
          </div>
        </div>
      </div>
    </div>

    <input
      type="file"
      id="avatar"
      @change="imgChange($event, 'avatar')"
      class="uploadInput"
      ref="filElem"
    />
  </div>
</template>
<script>
import {
  queryBankFlowList,
  queryEnterpriseInfoForUser,
  deleteBankFlow,
  modifyBankFlowYongTu,
} from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
import NoRecord from "@/components/NoRecord.vue";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      visit: sessionStorage.getItem("visit"),
      token: sessionStorage.getItem("pc-tkn"),
      jurisdiction: sessionStorage.getItem("jurisdiction"),
      bankLeftId: "",
      bankLeftName: "",
      bankName: "",
      value: "2021",
      yongtuShow: false,
      reportFlag: false,
      yongtu: "",
      rowNum: 0,
      item: {},
      bankNameList: [],
      bankLeftNameList: [],
      BankFlowList: [],
      oldDate: [],
      options: [
        {
          value: "2021",
          label: "2021",
        },
      ],

      pages: {
        total: 0,
        current: 1,
        pageSize: 999,
      },
    };
  },
  components: {
    TheFooter,
    NoRecord,
  },
  mounted() {
    this.queryBankFlowList();
    this.queryEnterpriseInfoForUser();
    if (
      this.jurisdiction.indexOf("1") !== -1 ||
      this.jurisdiction.indexOf("4") != -1 ||
      this.jurisdiction.indexOf("45") != -1
    ) {
      this.reportFlag = true;
    }
  },
  methods: {
    deleteBankFlow(item) {
      if (item.state === 1) {
        this.$store.commit("MyMessage", "正在处理中的记录不能进行删除操作!");
        return false;
      }
      this.$confirm("确认要删除此记录？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let param = {
            enterpriseId: this.enterpriseId,
            bankFlowId: item.id,
          };
          deleteBankFlow(param).then((res) => {
            console.log(res);
            if (res.code === 0) {
              this.$store.commit("MyMessage", "删除成功");
              this.queryBankFlowList();
            } else {
              this.$store.commit("MyMessage", res.msg);
            }
          });
        })
        .catch(() => {});
    },
    modifyBankFlowYongTu() {
      let param = {
        enterpriseId: this.enterpriseId,
        bankFlowId: this.item.id,
        yongtu: this.item.yongtu,
        userId: this.userId,
      };
      modifyBankFlowYongTu(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.yongtuShow = false;
          this.$store.commit("MyMessage", "修改成功");
          this.queryBankFlowList();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    editClicl(item) {
      if (this.reportFlag) {
        this.item = item;
        this.yongtuShow = true;
      }
    },
    open() {
      this.$confirm("请下载模板填入后，再上传", "提示", {
        confirmButtonText: "下载",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          window.open("https://www.jzcfo.com/download/银行对账单模版文件.xlsx");
        })
        .catch(() => {});
    },
    queryEnterpriseInfoForUser(item) {
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        console.log("res", res);
        if (res.code === 0) {
          this.bankLeftNameList = res.data.bankList;
          this.bankLeftNameList.forEach((element, index) => {
            if (this.bankLeftNameList.length === 1 && index === 0) {
              this.bankLeftName = element.bankName;
              this.bankLeftId = element.bankcardId;
            }
            element.bankNameText =
              element.bankName +
              " *" +
              element.bankCardNum.substr(element.bankCardNum.length - 4, 4);
          });
        }
      });
    },
    queryBankFlowList() {
      let param = {
        enterpriseId: this.enterpriseId,
        pageNum: this.pages.current,
        pageSize: this.pages.pageSize,
        userId: this.userId,
      };
      queryBankFlowList(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.BankFlowList = res.data;
          this.oldDate = this.$config.cloneObj(res.data);
          if (res.data.length > 0) {
            this.bankNameList = [];
            this.bankName = res.data[0].belongtoBank;

            res.data.forEach((e, index) => {
              let flag = false;
              this.bankNameList.forEach((e1) => {
                if (e.belongtoBank === e1.value && e.belongtoBank) {
                  flag = true;
                }
              });

              if (!flag) {
                this.bankNameList.push({
                  value: e.belongtoBank,
                  label: e.belongtoBank,
                });
              }
            });
            this.dataFun(this.bankName);
          }
        }
      });
    },
    dataLeftFun(bankLeftId) {
      this.bankLeftNameList.forEach((element, index) => {
        if (element.bankcardId === bankLeftId) {
          this.bankLeftName = element.bankName;
          this.bankLeftId = element.bankcardId;
        }
        element.bankNameText =
          element.bankName +
          " *" +
          element.bankCardNum.substr(element.bankCardNum.length - 4, 4);
      });
    },
    avatarFun() {
      if (this.bankLeftNameList.length === 0) {
        this.$confirm("请先完善银行信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.bankShowFlag = true;
          })
          .catch(() => {});
        return;
      }
      if (!this.bankLeftId) {
        this.$store.commit("MyMessage", "请先选择银行!");
        return;
      }
      this.$refs.filElem.dispatchEvent(new MouseEvent("click"));
    },
    imgChange(e, Id) {
      let that = this;
      const avatar = document.getElementById(Id);
      avatar.onchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {};
      };
      if (!avatar.files[0]) {
        this.$store.commit("MyMessage", "请选择文件!");
        return false;
      }
      const formData = new FormData();
      formData.append("file", avatar.files[0]);
      formData.append("userId", that.userId);
      formData.append("bankName", this.bankLeftName);
      formData.append("bankcardId", this.bankLeftId);
      formData.append("bankType", "bankType");
      formData.append("enterpriseId", that.enterpriseId);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let res = JSON.parse(xhr.responseText);
          if (res.code === 0) {
            // Toast("上传成功");
            this.$store.commit("MyMessage", "上传成功");
            this.queryBankFlowList();
          } else {
            if (res.msg === "流水重复或者已经存在") {
              this.$store.commit("MyMessage", "流水重复或者已经存在");
            } else {
              this.open();
            }
            // this.$store.commit("MyMessage", "请下载模板填入后，再上传。");
          }
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open("POST", url + "/bankbill/bankflow-service/upload-bankflow");
      xhr.setRequestHeader("token", that.token);
      xhr.send(formData);
      e.target.value = "";
    },
    dataFun(bankName) {
      console.log(bankName);
      let newDate = [];
      let newDate1 = [];
      this.rowNum = 0;

      this.oldDate.forEach((e) => {
        if (e.belongtoBank === bankName) {
          if (e.state === 3 || e.state === -1) {
            this.rowNum++;
          }
          newDate.push(e);
        }
      });
      let text = "";
      let index = null;
      newDate.forEach((e, i) => {
        let data1 = e.transDate.split("-")[1];
        if (text !== data1) {
          e.text = data1;
          text = data1;
          index = i;
          e.shoukuanAmtSum = 0;
          e.payAmtSum = 0;
        }

        newDate[index].shoukuanAmtSum =
          newDate[index].shoukuanAmtSum + e.shoukuanAmt.replace(/,/g, "") * 1;
        newDate[index].payAmtSum =
          newDate[index].payAmtSum + e.payAmt.replace(/,/g, "") * 1;
      });
      this.BankFlowList = newDate;
    },
  },
};
</script>

<style lang="less" scoped>
.accountTable {
  td {
    font-size: 13px;
  }
}
.qianbi {
  font-size: 12px;
}
.company-width {
  // min-width: 1360px;
  // max-width: 1600px;
  min-width: 1328px;
  max-width: 1328px;
  margin: 0px auto;
  width: calc(100% - 100px);
  // padding: 0px 50px;
}
.company {
  height: 100%;
  overflow-y: auto;
  .company-body {
    min-height: calc(100% - 105px);
  }
}
.company-body-header {
  height: 40px;
  // width: 100%;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 20px;
  .company-body-header-left {
    text-align: left;
    display: inline-block;
    width: 50%;
  }
  .company-body-header-right {
    width: 50%;
    text-align: right;
    display: inline-block;
    .el-select {
      width: 260px;
    }
  }
}
.uploadInput {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -999;
}
.download-link {
  color: #fff;
}
.report-colspan {
  width: 150px;
  padding: 0 5px;
  background: #f8f9fa;
}
.colspan-left {
  display: inline-block;
  width: calc(70% - 20px);
  text-align: left;
  padding-left: 20px;
  .colspan-span1 {
    display: inline-block;
    padding-right: 60px;
    font-size: 16px;
    font-weight: bold;
    color: #262626;
  }
  .colspan-span2 {
    display: inline-block;
    padding-right: 34px;
    font-size: 14px;
    font-weight: 400;
    color: #262626;
  }
}
.colspan-right {
  display: inline-block;
  width: calc(30%);
  text-align: right;
  .el-select {
    width: 100px;
  }
}

.report-span1 {
  padding: 0 5px;
  width: 86px;
  margin: 0 auto;
  white-space: normal;
  word-break: break-all;
}
.report-span2 {
  padding: 0 5px;
  width: 140px;
  margin: 0 auto;
  white-space: normal;
  word-break: break-all;
}
.report-span3 {
  padding: 0 5px;
  width: 120px;
  margin: 0 auto;
  white-space: normal;
  word-break: break-all;
}
.report-span4 {
  padding: 0 5px;
  width: 100px;
  margin: 0 auto;
  white-space: normal;
  word-break: break-all;
}
.report-span5 {
  padding: 0 5px;
  width: 80px;
  margin: 0 auto;
  white-space: normal;
  word-break: break-all;
}
.reportHover {
  position: relative;
  cursor: pointer;
  &:hover {
    .report-edit {
      display: inline-block;
    }
  }
  .report-edit {
    position: absolute;
    right: 0;
    top: 0;
    background: #dbdbdb;
    padding: 0 3px;
    display: none;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
  }
}
.report-span6 {
  width: 100px;
  padding: 5px;
  margin: 0 auto;
  white-space: normal;
  word-break: break-all;
}
.report-span7 {
  width: 110px;
  padding: 0 5px;
  margin: 0 auto;
  white-space: normal;
  word-break: break-all;
}
.report-span8 {
  width: 30px;
  padding: 0 5px;
  margin: 0 auto;
  white-space: normal;
  word-break: break-all;
  cursor: pointer;
  color: #4c8afc;
}
.password-main {
  width: 360px;
  height: 200px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -180px;
  margin-top: -180px;
}
.password-content {
  width: calc(100% - 80px);
  margin: 10px 40px 0;
}
.password-content-box {
  font-size: 14px;
  .password-box-l {
    width: 60px;
    float: left;
    height: 54px;
    line-height: 54px;
  }
  .password-box-r {
    width: calc(100% - 70px);
    margin-left: 10px;
    display: inline-block;
    height: 54px;
    line-height: 54px;
    .paddword-text {
      // padding-left: 15px;
    }
    input {
      width: 90%;
      height: 40px;
      border: 1px solid #dbdbdb;
      margin: 6px 0;
      border-radius: 4px;
      padding-left: 15px;
      &::placeholder {
        color: #999;
      }
    }
  }
}
.earmarked-footer {
  width: 100%;
  margin-top: 20px;
  .earmarked-btn {
    width: calc(100% - 77px);
    height: 34px;
    line-height: 34px;
    background: #4c8afc;
    border: 1px solid #1a73e8;
    opacity: 1;
    border-radius: 3px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    margin-left: 70px;
    cursor: pointer;
    text-align: center;
  }
}
</style>